import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import { HSRCharacter } from '../../../modules/hsr/common/components/hsr-character';
import { HSRLightCone } from '../../../modules/hsr/common/components/hsr-light-cone';
import { Col, Row } from 'react-bootstrap';

const HSRGuidesGachaPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Gacha system</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_gacha.png"
            alt="Gacha system"
          />
        </div>
        <div className="page-details">
          <h1>Honkai: Star Rail Gacha system</h1>
          <h2>Information about the gacha system in Honkai: Star Rail.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Gacha System" />
        <p>
          Honkai: Star Rail uses a gacha system as the primary way for a player
          to obtain new Characters and Light Cones. It is generally the only way
          to obtain the highest rarity Characters and Light Cones.
        </p>
        <SectionHeader title="Warps" />
        <p>
          Warp is what Honkai: Star Rail calls its gacha banners. To roll on the
          gacha, or warping, you will need to use either Stellar Jades, the
          gacha currency, or Passes/Special Passes which are the gacha tickets.{' '}
        </p>
        <ul>
          <li>
            You can exchange 160 Stellar Jades for 1 Pass or 1 Special Pass.
          </li>
          <li>Passes are used on the Beginner Warp and Standard Warp. </li>
          <li>
            Special Passes are used on the Character Oriented Warp and Light
            Cone Oriented Warp.
          </li>
        </ul>
        <p>
          Stellar Jades, Passes, and Special Passes can be obtained from various
          in-game sources such as missions, events, and certain shops.{' '}
        </p>
        <p>You can obtain the following from warping:</p>
        <ul>
          <li>3★ Light Cones</li>
          <li>4★ Light Cones</li>
          <li>5★ Light Cones</li>
          <li>4★ Characters</li>
          <li>5★ Characters</li>
        </ul>
        <p>
          Stars denote the rarity of the Character or Light Cone. The higher
          number of stars, the higher the rarity (and the more difficult) it is
          to obtain.
        </p>
        <p>
          <strong>4 ★ Guarantee</strong>: If a player does not obtain a 4 ★
          Character or Light Cone after 9 warps, the 10th warp is guaranteed to
          be a 4★ Character or Light Cone. This counter reset every time a
          player obtains a 4★ Character or Light Cone.
        </p>
        <SectionHeader title="Undying Embers & Undying Starlights" />
        <p>
          Undying Embers and Undying Starlights are in-game currencies that are
          only obtainable by warping. They can be used in the Embers Exchange
          and Starlight Exchange to purchase Passes, Special Passes, upgrade
          materials, and other resources.
        </p>
        <p>
          You can get Undying Embers by obtaining a 3★ Light Cone from warping.
          Each 3 ★ Light Cone will give you 20 Undying Embers.
        </p>
        <p>You can obtain Undying Starlights from:</p>
        <ul>
          <li>Obtaining any 4★ Light Cone</li>
          <ul>
            <li>8 Undying Starlights</li>
          </ul>
          <li>Obtaining any 5★ Light Cone</li>
          <ul>
            <li>40 Undying Starlights</li>
          </ul>
          <li>Obtaining a duplicate 4★ Character</li>
          <ul>
            <li>
              1st to 6th duplicate: 8 Undying Starlights and 1 Eidolon for the
              Character
            </li>
            <li>7th duplicate onwards: 20 Undying Starlights</li>
            <li>
              Note that you will not receive any Undying Starlights when you
              pull a 4 Star Character that you do not already own for the first
              time.
            </li>
          </ul>
          <li>Obtaining a duplicate 5★ Character</li>
          <ul>
            <li>
              1st to 6th duplicate: 40 Undying Starlights and 1 Eidolon for the
              Character
            </li>
            <li>7th duplicate onwards: 100 Undying Starlights</li>
            <li>
              Note that you will not receive any Undying Starlights when you
              pull a 5 Star Character that you do not already own for the first
              time.
            </li>
          </ul>
        </ul>
        <SectionHeader title="Departure Warp" />
        <StaticImage
          src="../../../images/starrail/generic/guide_warpbeg.webp"
          alt="Guide"
        />
        <h5>Information about this banner</h5>
        <ul>
          <li>
            You can only warp 50 times on the Departure Warp, but you are
            guaranteed to obtain one 5★ Character within the 50 warps,
          </li>
          <li>
            You cannot do single warps on the Departure Warp. You can only warp
            in multiples of 10 (commonly referred to as a ten pull or a multi
            pull). However, you will receive a 20% discount every time you warp,
            so it will only cost 8 Passes instead of 10,
          </li>
          <li>
            The rate for pulling a 5★ Character from this banner is currently
            set at <strong>0.6%</strong>,
          </li>
          <li>
            The rate for pulling a 4★ Character from this banner is currently
            set at <strong>5.1%</strong>,
          </li>
          <li>
            No characters or Light Cones have an increased rate on this Warp.
          </li>
          <li>
            You can only use Star Rail Passes on this banner (basic summon
            ticket).
          </li>
        </ul>
        <p>
          You can only obtain one of the below 5★ Characters from the Departure
          Warp:
        </p>
        <div className="employee-container for-nikke">
          <HSRCharacter mode="icon" slug="bailu" enablePopover />
          <HSRCharacter mode="icon" slug="bronya" enablePopover />
          <HSRCharacter mode="icon" slug="clara" enablePopover />
          <HSRCharacter mode="icon" slug="gepard" enablePopover />
          <HSRCharacter mode="icon" slug="himeko" enablePopover />
          <HSRCharacter mode="icon" slug="welt" enablePopover />
          <HSRCharacter mode="icon" slug="yanqing" enablePopover />
        </div>
        <p>
          Please note that you are guaranteed to get one 5★ Character from this
          banner. When you pull a 5★ before reaching the pity, you won't get
          another in your 50 pull. Still, you can get more 5★ Characters from
          the banner, but it's very very rare.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
        <SectionHeader title="Standard Warp" />
        <StaticImage
          src="../../../images/starrail/generic/guide_warpstand.webp"
          alt="Guide"
        />
        <p>No characters or Light Cones have an increased rate on this Warp.</p>
        <p>
          You can obtain both 5★ Characters and Light Cones from this Warp.
          Possible 5★ Characters:
        </p>
        <div className="employee-container for-nikke">
          <HSRCharacter mode="icon" slug="bailu" enablePopover />
          <HSRCharacter mode="icon" slug="bronya" enablePopover />
          <HSRCharacter mode="icon" slug="clara" enablePopover />
          <HSRCharacter mode="icon" slug="gepard" enablePopover />
          <HSRCharacter mode="icon" slug="himeko" enablePopover />
          <HSRCharacter mode="icon" slug="welt" enablePopover />
          <HSRCharacter mode="icon" slug="yanqing" enablePopover />
        </div>
        <p>Possible 5★ Light Cones:</p>
        <Row xs={1} xxl={2} className="relic-set-container">
          <Col>
            <HSRLightCone mode="card" slug="but-the-battle-isnt-over" />
          </Col>
          <Col>
            <HSRLightCone mode="card" slug="in-the-name-of-the-world" />
          </Col>
          <Col>
            <HSRLightCone mode="card" slug="moment-of-victory" />
          </Col>
          <Col>
            <HSRLightCone mode="card" slug="night-on-the-milky-way" />
          </Col>
          <Col>
            <HSRLightCone mode="card" slug="something-irreplaceable" />
          </Col>
          <Col>
            <HSRLightCone mode="card" slug="sleep-like-the-dead" />
          </Col>
          <Col>
            <HSRLightCone mode="card" slug="time-waits-for-no-one" />
          </Col>
        </Row>
        <h5>Additional information</h5>
        <ul>
          <li>
            The rate for pulling a 5★ Character from this banner is currently
            set at <strong>0.3%</strong> (and 0.3% for a 5★ Light Cone),
          </li>
          <li>
            The rate for pulling a 4★ Character from this banner is currently
            set at <strong>5.1%</strong>,
          </li>
          <li>
            You are guaranteed to obtain a 5★ Character or a 5★ Character Light
            Cone within 90 pulls,
          </li>
          <li>
            Once you do 300 pulls you will be able to pick one 5★ available in
            the banner,
          </li>
          <li>
            You can only use Star Rail Passes on this banner (basic summon
            ticket).
          </li>
        </ul>
        <SectionHeader title="Character Oriented Warp" />
        <StaticImage
          src="../../../images/starrail/generic/guide_warptarget.webp"
          alt="Guide"
        />
        <h5>Information about this banner</h5>
        <ul>
          <li>
            One 5★ Character and three 4★ Characters will be featured on any
            given Character Oriented Warp,
          </li>
          <li>
            The rate for pulling a 5★ Character from this banner is currently
            set at <strong>0.6%</strong>,
          </li>
          <li>
            The rate for pulling a 4★ Character from this banner is currently
            set at <strong>5.1%</strong>,
          </li>
          <li>
            Once you pull a 5★ Character, you have 50% chance of it being the
            Rate-up one,
          </li>
          <li>
            If you lose the 50/50 then the second 5★ Character you pull will
            100% be the Rate-up one,
          </li>
          <li>
            You are guaranteed to obtain a 5★ within 90 pulls (that's the pity).
            So if you're unlucky, it will take you at most 180 pulls to get the
            rate-up character.
          </li>
          <li>
            You can only use Star Rail Special Passes on this banner (special
            summon ticket).
          </li>
        </ul>
        <SectionHeader title="Light Cone Oriented Warp" />
        <StaticImage
          src="../../../images/starrail/generic/guide_warpcone.webp"
          alt="Guide"
        />
        <h5>Information about this banner</h5>
        <ul>
          <li>
            One 5★ Light Cone and three 4★ Light Cones will be featured on any
            given Light Cone Oriented Warp,
          </li>
          <li>
            The rate for pulling a 5★ Light Cone from this banner is currently
            set at <strong>0.8%</strong>,
          </li>
          <li>
            The rate for pulling a 4★ Light Cone from this banner is currently
            set at <strong>6.6%</strong>,
          </li>
          <li>
            Once you pull a 5★ Light Cone, you have 75% chance of it being the
            Rate-up one,
          </li>
          <li>
            If you lose the roll then the second 5★ Light Cone you pull will
            100% be the Rate-up one,
          </li>
          <li>
            You are guaranteed to obtain a 5★ within 80 pulls (that's the pity).
            So if you're unlucky, it will take you at most 160 pulls to get the
            rate-up Light Cone.
          </li>
          <li>
            You can only use Star Rail Special Passes on this banner (special
            summon ticket).
          </li>
        </ul>
      </div>
    </DashboardLayout>
  );
};

export default HSRGuidesGachaPage;

export const Head: React.FC = () => (
  <Seo
    title="Gacha system | Honkai: Star Rail | Prydwen Institute"
    description="Information about the gacha system in Honkai: Star Rail."
  />
);
